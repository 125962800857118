import { useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';

import { useMutation } from 'react-query';

import { useCart } from "../../hooks";

import Order from "../../order";

import CheckoutForm from "./CheckoutForm";

import CheckoutConfirm from './CheckoutConfirm';

const Checkout = (props: any) => {
    const { onCheckedOut, order, paypal, status, subtotal, params } = props;
    // Dependencies.
    const { addCartToOrder, getOrderId } = useCart();
    const [ checkedOut, setCheckedOut ] = useState(false);
    const [ message, setMessage ] = useState<any>('PLEASE WAIT');
    const [ ordered, setOrdered ] = useState<any>();
    const [ orderId, setOrderId ] = useState();
    const [ email, setEmail ] = useState();
    const [ shipping, setShipping ] = useState();
    const [ tax, setTax ] = useState();
    const stripe: any = useStripe();
    const elements: any = useElements();
    // Update order (create/set (edit)).
    interface IOrder {email: string, name: string, billing: any, shipping: any, payment: any};
    const { mutateAsync: updateOrder } = useMutation(async ({email, name, billing, shipping, payment}: IOrder) => {
        const orderId = getOrderId();
        // Reduce item details.
        const items = order.map((item: any) => ({
            model: item.model,
            quantity: item.quantity
        }));
        if( orderId === undefined ) {
            return new Order().create({data: {messageId: paypal === undefined ? 'checkout' : 'paypal', message: {
                    items: items,
                    email: email,
                    name: name,
                    billing: billing,
                    shipping: shipping,
                    payment: payment
                }}}).then(response => {
                addCartToOrder(response.message.id);
                setOrderId(response.message.orderId); // This is the friendly Id to show the user/customer.
                return response.message;
            });
        } else {
            return new Order().set({data: {messageId: 'checkin', message: {
                id: orderId,
                items: items,
                email: email,
                name: name,
                billing: billing,
                shipping: shipping,
                payment: payment
            }}}).then(response => {
                return response.message;
            });
        }
    });
    async function onReviewOrder(payment: any) {
        const {error: error} = await elements.submit();
        // Any shipping/payment information errors.
        if( error ) {
            return { error };
        } else if ( stripe ) {
            // Submit payment.
            const { error, confirmationToken } = await stripe.createConfirmationToken({elements: elements});
            if( error ) {
                return { error };
            } else {
                // Billing details.
                const billing = {
                    tokenId: confirmationToken.id,
                    name: confirmationToken.payment_method_preview.billing_details.name,
                    address: confirmationToken.payment_method_preview.billing_details.address,
                    card: String(confirmationToken.payment_method_preview.card.display_brand).toUpperCase(),
                    last4: confirmationToken.payment_method_preview.card.last4,
                    expiry: `${String('0' + confirmationToken.payment_method_preview.card.exp_month).slice(-2)}/${confirmationToken.payment_method_preview.card.exp_year}`,
                };
                // Reduce item details.
                const items = payment.order.map((item: any) => ({
                    model: item.model,
                    quantity: item.quantity
                }));
                // Order details (e.g., Taxes).
                const result = await new Order().get({data: {messageId: 'details', message: {items: items, address: payment.address}}});
                return {
                    billing,
                    shipment: result.message === undefined ? undefined : result.message.shipment,
                    tax: result.message === undefined ? undefined : result.message.tax
                };
            }
        }
    }
    async function onPlaceOrder(payment: any) {
        try {
                setMessage('PLEASE WAIT .');
                const response = await updateOrder({
                    email: payment.email,
                    name: payment.name,
                    billing: {
                        card: payment.billing.card,
                        last4: payment.billing.last4,
                        address: payment.billing.address
                    },
                    shipping: {
                        address: payment.shipping.address
                    },
                    payment: {
                        id: payment.id,
                        // This does not seem to be used now that  the
                        // payment is being confirmed on the server side.
                        return_url: 'http://localhost:3000/cart',
                        tax: {
                            amount: payment.tax === undefined ? 0 : payment.tax.amount,
                            rate: payment.tax === undefined ? 0 : payment.tax.rate
                        }
                    }
                });
                if( response.result === true ) {
                    setCheckedOut(true);
                    setOrdered(order);
                    onCheckedOut(order, true);
                    setEmail(payment.email);
                    setShipping(payment.shipping);
                    setTax(payment.tax === undefined ? {amount: 0, rate: 0} : payment.tax);
                    return {error: undefined};
                } else {
                    setCheckedOut(false);
                    onCheckedOut(order, false);
                    return {error: response.error};
                }
        } catch(error: any) {
            return { error };
        }
    }
    return (
        checkedOut === true ?
        <CheckoutConfirm id={orderId} email={email} order={ordered} shipping={shipping} tax={tax} /> :
        <CheckoutForm order={order} paypal={paypal} message={message} status={status} onReviewOrder={onReviewOrder} onPlaceOrder={onPlaceOrder} />
    );
}

export default Checkout;