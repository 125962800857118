import { useEffect, useState } from "react";

import './Slider.css';

const Slider = (props: any) => {
    const { slides } = props;
    const [ direction, setDirection ] = useState('left');
    const [ firstSlideIndex, setFirstSlideIndex ] = useState(slides.length-1);
    const [ secondSlideIndex, setSecondSlideIndex ] = useState(0);
    const [ paused, setPaused ] = useState(false);
    const [ sliding, setSliding ] = useState(false);

    const currentSlides = [slides[firstSlideIndex], slides[secondSlideIndex]];

    useEffect(() => {
        return startSlide();
    });
    const nextSlide = () => {
        setDirection('left');
        const nextSlideIndex = secondSlideIndex === slides.length-1 ? 0 : slides.length > 1 ? secondSlideIndex+1 : 0;
        setFirstSlideIndex(secondSlideIndex);
        setSecondSlideIndex(nextSlideIndex);
        setSliding(true);
        stopSlide();
    }
    const previousSlide = () => {
        setDirection('right');
        const nextSlideIndex = secondSlideIndex === 0 ? slides.length-1 : slides.length > 1 ? secondSlideIndex-1 : 0;
        setFirstSlideIndex(secondSlideIndex);
        setSecondSlideIndex(nextSlideIndex);
        setSliding(true);
        stopSlide();
    };
    const setSlide = (index: any) => {
        setDirection('jump');
        const nextSlideIndex = index === slides.length-1 ? 0 : slides.length > 1 ? index+1 : 0;
        console.log(`index: ${index}, next: ${nextSlideIndex}, 1st: ${firstSlideIndex}, 2nd: ${secondSlideIndex}`);
        if( index === firstSlideIndex ) {
            setFirstSlideIndex(secondSlideIndex);
        } else if( nextSlideIndex != firstSlideIndex ) {
            setFirstSlideIndex(nextSlideIndex);
        } else {
            setFirstSlideIndex(secondSlideIndex);
        }
        setSecondSlideIndex(index);
    }
    const startSlide = () => {
        const interval = setInterval(() => {
            if (!paused) {
                setDirection('left');
                nextSlide();
            }
        }, 5000);
        return () => { clearInterval(interval); };
    }
    const stopSlide = () => {
        const timeout = setTimeout(() => {
            setSliding(false);
        }, 900);
        return () => { clearTimeout(timeout) };
    }
    return (
        <>
            <div className='container'>
                {!!currentSlides.length && currentSlides.map((slide, index) => {
                        return <div className={`item ${direction}`} onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)} key={`slide${slide.id}`}>
                            <picture>
                                {/*<source media='(min-width:1100px)' srcSet={slide.image} />*/}
                                {(sliding === true || index > 0) && <img className='image' src={slide.image} alt='image' />}
                            </picture>
                            <div className='box'>
                                <h1 className='title'>{slide.title}</h1>
                                <h4 className='subtitle'>{slide.subtitle}</h4>
                                {slide.text && <a className='button' href={slide.link}>{slide.text}</a>}
                            </div>
                        </div>
                    })
                }
                <button className="arrow arrow_left" onClick={previousSlide}>
                    <img src='/images/prev.png' width='25%' height='25%' alt='Click to previous image' aria-label='Click to previous image' />
                </button>
                <button className="arrow arrow_right" onClick={nextSlide}>
                    <img src='/images/next.png' width='25%' height='25%' alt='Click to next image' aria-label='Click to next image' />
                </button>
            </div>
            <table>
            <thead></thead>
            <tbody>
                <tr className='scroll'><td>
                </td></tr>
                <tr style={{height: '100px'}}><td>
                    <span className='indicators'>
                    {slides.map((slide: any, index: any) => {
                        return <button className={secondSlideIndex === index ? 'indicator' : 'indicator inactive'} onClick={() => setSlide(index)} key={`indicator${index}`} />
                    })}
                    </span>
                </td></tr>
            </tbody>
            </table>
        </>
    );
}

export default Slider;