import { Link } from 'react-router-dom';

import CartItem from '../Cart/CartItem';

import CartSummary from '../Cart/CartSummary';

import Footer from '../Controls/Footer';

const CheckoutConfirm = (props: any) => {
    const { id, email, order, shipping, tax } = props;
    return (
        <div className='cart'>
            <table width='100%'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{whiteSpace: 'normal'}}>
                        <br/>{order.length > 0 && <h3 style={{fontWeight: 'normal'}}>&nbsp;{`Thank you for your order, a confirmation has been sent to your email, `}<b>{email}</b></h3>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <h2 style={{margin: '0px'}}>Details</h2>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Order Id:&nbsp;{id}
                        </td>
                    </tr>
                    <tr>
                        <td width='100%' align='center'>
                        {order.map((sunglass: any, index: any) => {
                            return <CartItem sunglass={sunglass} key={`order${index}`} />})}
                        </td>
                    </tr>
                    <tr>
                        <td className='pricingtotal' width='100%'>
                            <CartSummary order={order} shipping={shipping} tax={tax} />
                        </td>
                    </tr>
                    <tr>
                        <td width='100%' align='center'>                        
                        <br/><br/><Link to='/' style={{textDecoration: 'none'}}>
                        <button className="rectangle"><b>KEEP SHOPPING</b></button>
                        </Link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Footer position='absolute' />
        </div>
    );
}

export default CheckoutConfirm;