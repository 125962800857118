const Footer = (props: any) => {
    const { position, bottom, image } = props;
    const mail = 'mail';
    const to = 'to';
    const symbol = '@';
    const period = '.';
    const style = {...{left: '0px', bottom: bottom === undefined ? '0px' : bottom, width: '100%'}, ...position === undefined ? {} : {position: position}};
    return (
        <div className='footer' style={style}>
            <table width='100%' style={{padding: '10px'}}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                        <table width='100%'>
                            <thead></thead>
                            <tbody>
                                <tr>                            
                                    <td valign='bottom' style={{whiteSpace: 'nowrap'}}>
                                    Hava Sunglasses
                                    </td>
                                    <td align='right'>
                                        {image && <img src={image} width='150'/>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 'small', whiteSpace: 'nowrap'}}>
                        © {new Date().getFullYear()} Hava Sunglasses.  All rights reserved.
                        </td>
                        <td align='right' style={{fontSize: 'small', whiteSpace: 'nowrap'}}>
                        <a href={`${mail+to}:sales${symbol}havasunglasses${period}com`}>Contact Us</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Footer;