import { useQuery } from "react-query";

import Slider from "../Controls/Slider"
import Menu from '../Menu';
import Banner from '../Controls/Banner';
import Catalog from '../Controls/Catalog';
import Footer from "../Controls/Footer";

import System from "../../system";

import '../../styles.css';

const Home = () => {
    const quantity = 3; // Used for columns in a row for the catalog.
    const { isLoading: loadingSystem, data: system } = useQuery('Home', async () => {
        // Interested in the resources (pictures) to display.
        const result = await new System().get({data: {messageId: 'default', message: {}}});
        const message = result.message;
        return message;
    });
    const { isLoading: loadingProducts, data: products } = useQuery('Products', async () => {
        // Currently gets the entire product catalog.
        const result = await new System().get({data: {messageId: 'default', messageTable: 'Products', message: {}}});
        return {sunglasses: result.message};
    });
    // Featured images.
    const slides = loadingSystem === true ? [] : system === undefined || system.resources === undefined ? [] : system.resources.map((resource: any, index: number) => ({
        id: resource.id,
        image: resource.file,
        imageMobile: resource.file
    }));
    return (
        <div style={{backgroundImage: system === undefined || system.title === undefined ? `url('')` : `url(${system.title.file})`, backgroundRepeat: 'no-repeat'}}>
            <Menu />
            <div style={{marginTop: 40}}>
                {slides.length > 0 ? <Slider slides={slides} /> :
                // Placeholder until images for slider are loaded.
                <div style={{height: '50vh'}}>&nbsp;</div>
                }
            </div>
            <div className='center' style={{width: '100%', display:'flex', alignItems: 'left', flexDirection:'column'}}>
                {system !== undefined && system.title !== undefined && <Banner title={system.title.message} />}
                {products !== undefined && products.sunglasses.map((sunglass: any, index: number) => {
                    if( index % quantity === 0 ) {
                        // Start is 0 based, but end, to work with slice, is 1 based.
                        const length = index + Math.min((products.sunglasses.length-index), quantity);                        
                        const items = products?.sunglasses.slice(index, Math.min(length, products.sunglasses.length));
                        return <Catalog items={items} columns={quantity} key={`catalog${index}`} />;
                    }
                })}
            </div>
            <Footer position='relative' />
        </div>
    );
}

export default Home;