const Banner = (props: any) => {
    const { title } = props;
    return (
        <div className='banner'>
            <span className='season'>
                <p>{title}</p>
            </span>
        </div>
    );
}

export default Banner;