import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import Home from './components/Home/Home';
import Products from './components/Products';
import About from './components/About';
import Details from './components/Details';
import Cart from './components/Cart';
import Checkout from './components/Checkout';

function MyApp() {
  try {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<Products />} />
          <Route path='/about' element={<About />} />
          <Route path='/details' element={<Details />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  } catch(error :any) {
    const message = error.message;
    return (
      <div><pre>{message}</pre></div>
    );
  }
}

function App() {
  try {
    return (
      <QueryClientProvider client={new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      })}>
          <MyApp />
      </QueryClientProvider>
    );
  } catch(error :any) {
    const message = error.message;
    return (
      <div><pre>{message}</pre></div>
    );
  }
}

export default App;