import { useMemo, useState } from 'react';

import { useMutation } from 'react-query';

import { useCart } from "../../hooks";

import Order from '../../order';

import CartItem from './CartItem';
import CartSummary, { getSubtotal } from './CartSummary';

import PayPal from '../Checkout/PayPal';

const CartContent = (props: any) => {
    const { error, onCheckout, onAddToCart, onRemoveFromCart } = props;
    const { cart, groupCart, getOrderId } = useCart();
    const [ order, setOrder ] = useState(cart);
    const { mutateAsync: deleteOrder } = useMutation('Order', async (id: string) => {
        // Last item?  If so, delete order.
        if( order.length === 1 && id !== undefined ) {
            return await new Order().delete({data: {messageId: 'order', message: {id: id}}});
        }
    });
    const orderGrouped = useMemo(() => {
        if( order.length === 0 ) {
            return [];
        } else {
            return groupCart(order, 'id');
        }    
    }, [order]);
    function onReviewOrder(payment: any) {
        onCheckout(orderGrouped, getSubtotal(orderGrouped), payment);
    }
    function onDelete(sunglass: any) {
        // Before removing from cart, preserve the order Id 
        // so it can be requested to be deleted, if necessary.
        const orderId = getOrderId();

        // Remove from cart but tell parent
        // component so menu item can be updated
        setOrder(onRemoveFromCart(sunglass));

        // Last item in order?
        if( order.length === 1 ) {
            deleteOrder(orderId);
        }
    }
    return (
        <div className='cart'>
            {orderGrouped.length > 0 && <h2>Your Order</h2>}
            {error && <h4 className='error'>{error}</h4>}
            <table width='100%'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td width='100%' colSpan={2} align='center'>
                        {orderGrouped.length === 0 ?
                        <div className='cartempty'>
                            You have no items in your cart.
                        </div> :
                        orderGrouped.map((sunglass: any, index: any) => {
                            return <CartItem sunglass={sunglass} onDelete={onDelete} key={`order${index}`} />})}
                        </td>
                    </tr>
                    <tr>
                        <td className='pricingtotal' width='100%'>
                            <CartSummary order={orderGrouped} />
                        </td>
                    </tr>
                    {orderGrouped.length > 0 &&
                    <tr>
                        <td width='100%' colSpan={2} align='center'>
                        <br/><br/>
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td width='200px' style={{padding: '5px 10px'}}>
                                    <button style={{width:'100%'}} className="rectangle" onClick={() => onCheckout(orderGrouped, getSubtotal(orderGrouped))}><b>CHECKOUT</b></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{padding: '5px 10px'}}>
                                    <PayPal order={orderGrouped} onReviewOrder={onReviewOrder} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    );
}

export default CartContent;