import CartItem from '../Cart/CartItem';
import CartSummary from '../Cart/CartSummary';

const Billing = (props: any) => {
    const { billing } = props;
    return (
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td>
                    <b>BILLING DETAILS</b>
                    </td>
                </tr>
                {billing.card && <tr>
                    <td>
                    {billing.card}&nbsp;. . .{billing.last4}
                    </td>
                </tr>}
                {billing.expiry && <tr>
                    <td>
                    Exp: {billing.expiry}
                    </td>
                </tr>}
                {billing.vendor && <tr>
                    <td>
                    {billing.vendor}
                    </td>
                </tr>}
                <tr>
                    <td>
                    &nbsp;
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

const BillingAddress = (props: any) => {
    const { billing, name } = props;
    return (
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td>
                    <b>BILLING ADDRESS</b>
                    </td>
                </tr>
                <tr>
                    <td>
                    {name}
                    </td>
                </tr>
                {billing.address.line1 &&
                <tr>
                    <td>
                    {billing.address.line1}
                    {billing.address.line2 && <><br/>{billing.address.line2}</>}
                    </td>
                </tr>}
                <tr>
                    <td>
                    {billing.address.city && `${billing.address.city},`} {billing.address.state} {billing.address.postal_code}
                    </td>
                </tr>
                {/*<tr>
                    <td>
                    Country
                    </td>
                    <td>
                    <b>{billing.address.country}</b>
                    </td>
                </tr>*/}
            </tbody>
        </table>
    );
}

const ShippingAddress = (props: any) => {
    const { shipping } = props;
    return (
        <table>
            <thead></thead>
            <tbody>
                <tr>
                    <td>
                    <b>SHIPPING ADDRESS</b>
                    </td>
                </tr>
                <tr>
                    <td>
                    {shipping.name}
                    </td>
                </tr>
                <tr>
                    <td>
                    {shipping.address.line1}
                    {shipping.address.line2 && <><br/>{shipping.address.line2}</>}
                    </td>
                </tr>
                <tr>
                    <td>
                    {shipping.address.city}, {shipping.address.state} {shipping.address.postal_code}
                    </td>
                </tr>
                {/*<tr>
                    <td>
                    Country
                    </td>
                    <td>
                    <b>{shipping.address.country}</b>
                    </td>
                </tr>*/}
            </tbody>
        </table>
    );
}

const ReviewElement = (props: any) => {
    const { email, order, billing, shipping, tax } = props;
    return (
        <div className='cart'>
            {order.length > 0 && <h2>&nbsp;Your Order</h2>}
            <table width='100%'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                        <b>CONTACT</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        {email}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        {billing && <Billing billing={billing} />}
                        </td>
                    </tr>
                    <tr>
                        {billing && billing.address && <td valign='top'>
                        <BillingAddress billing={billing} name={billing.name ? billing.name : shipping.name} />
                        </td>}
                        <td valign='top'>
                        {shipping && <ShippingAddress shipping={shipping} />}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                        &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td width='100%' colSpan={2} align='center'>
                        {order.map((sunglass: any, index: any) => {
                            return <CartItem sunglass={sunglass} key={`order${index}`} />})}
                        </td>
                    </tr>
                    <tr>
                        <td className='pricingtotal' width='100%' colSpan={2}>
                            <CartSummary order={order} shipping={shipping} tax={tax === undefined ? {amount: 0} : tax} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ReviewElement;