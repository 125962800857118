import { useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useMutation, useQuery } from 'react-query';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';

import { useCart } from "../../hooks";

import Order from '../../order';

import Menu from "../Menu";
import Footer from '../Controls/Footer';

import Checkout from '../Checkout';

import CartContent from './CartContent';

const Cart = () => {
    const [ searchParams ] = useSearchParams();
    const { cart, addCartToOrder, clearCart, removeFromCart } = useCart();
    const [ order, setOrder ] = useState(cart);
    const [ error, setError ] = useState<any>();
    const [ status, setStatus] = useState('REVIEW ORDER');
    const [ paypal, setPayPal ] = useState();
    const [ checkout, setCheckout ] = useState(searchParams.size > 0);
    const [ checkedOut, setCheckedOut] = useState();
    const [ subtotal, setSubtotal ] = useState(0);
    const [ stripe, setStripe ] = useState<any>();
    const { isLoading, data } = useQuery('Payment', () => {
        return new Order().get({data: {messageId: 'payment', message: {}}}).then(response => {
            return response.message;
        });
    });
    interface ILog {params: any};
    const { mutateAsync: createLog, /*isLoading: isLogging*/ } = useMutation(async ({params}: ILog) => {
        if( params !== undefined && params.sunglasses !== undefined ) {
            const result = await new Order().create({data: {messageId: 'default', messageTable: 'Logs', message: 
                {
                    description: `${params.checkout}: name: ${params.sunglasses.name}, model: ${params.sunglasses.model}.`,
                    level: 'Info'
                }
            }});
        }
    });
    useMemo(() => {
        if( data !== undefined && data.paymentKey !== undefined) {
            if( stripe === undefined ) {
                setStripe(loadStripe(data.paymentKey));
            }
        }
    }, [data]);
    function onCart() {
        setCheckout(false);
    }
    function onAddToCart(orderId: string) {
        return (addCartToOrder(orderId));
    }
    function onRemoveFromCart(sunglass: any) {
        const cart = removeFromCart(sunglass);
        setOrder(cart);
        return cart;
    }
    function onCheckout(order: any, subtotal: any, payment: any) {
        if( payment === undefined ) {
            createLog({params: {checkout: 'Payment', sunglasses: order[0]}});
            setStatus('REVIEW ORDER');
            setPayPal(undefined);
        } else {
            createLog({params: {checkout: 'PayPal', sunglasses: order[0]}});
            setStatus('PLACE ORDER');
            setPayPal(payment);
        }
        setCheckout(true);
        setOrder(order);
        setSubtotal(subtotal);
    }
    function onCheckedOut(order: any, checkedOut: any) {
        setCheckedOut(checkedOut);

        if( checkedOut === true ) {
            const cart: any = [];        
            clearCart();
            setOrder(cart);
            setError(undefined);
        } else {
            setCheckout(false);
            setOrder(order);
            setError('There was a problem placing your order.  We apologize for the inconvenience and ask to please try again later.');
        }
    }
    return (
        <div>
            <Menu onCart={onCart} />
            {checkout === true ? stripe === undefined ?
            <div className='center'>
                <div className="checkingout">
                {searchParams.size > 0 ? 'PLEASE WAIT' : 'PLEASE WAIT'/*'Check out is not currently available, please try again later...'*/}
                </div>
            </div> :
            // <Elements> is made to be used inside the top level <App>
            // so that stripe is available anywhere in the application.
            // Checking out.
            <Elements stripe={stripe} options={{
                mode: 'payment',
                amount: Number(subtotal)*100,
                currency: 'usd',
                paymentMethodCreation: 'manual',
                // Fully customizable appearance.
                appearance: {}
            }}>
                <Checkout order={order} paypal={paypal} subtotal={subtotal} status={status} params={searchParams} onCheckedOut={onCheckedOut} />
            </Elements> :
            // Ordering.
            <>
            <CartContent error={error} onCheckout={onCheckout} onAddToCart={onAddToCart} onRemoveFromCart={onRemoveFromCart} />
            <Footer position={(order === undefined || order.length === 0) && checkedOut === undefined ? 'absolute' : undefined} />
            </>}
        </div>
    )
}

export default Cart;