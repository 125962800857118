import { Link } from 'react-router-dom';

import CartMenuItem from '../Cart/CartMenuItem';

const Menu = (props: any) => {
    const { onCart } = props;
    return (
        <div className='center' style={{padding: '10px', display:'flex', flexDirection:'row'}}>
            <Link to='/' style={{textDecoration: 'none'}}>
                <img className='logo' src='/images/hava.png' alt='Hava' aria-label='Hava' />
            </Link>
            <table style={{width: '100%', paddingRight: '12px', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td><Link className='menu' to='/'>Home</Link>&nbsp;</td>
                        <td>&nbsp;<Link className='menu' to='/products'>Products</Link>&nbsp;</td>
                        <td>&nbsp;<Link className='menu' to='/about'>About</Link></td>
                        <td><CartMenuItem onClick={onCart} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Menu;