import { useCallback, useEffect, useMemo, useState } from "react";

const Progress = (props: any) => {
    const { open, message, timeout, zIndex } = props;
    const [ status, setStatus ] = useState(message);

    function updateProgress(progress: string) {
        console.log(status + progress);
        setStatus(status + progress);
    }
    useEffect(() =>{
        // Update when there is enough "progress".
        if( open === true && String(message).endsWith(' .')) {
            // Too much "progress"?
            if( String(status).endsWith(' . . .') === false ) {
                const timer = setTimeout(() => updateProgress(' .'), timeout === undefined ? 1500 : timeout);
                return () => clearTimeout(timer);
            }
        }
    }, [message, status]);

    return (
        <div style={{position: 'absolute', left: '0', top: '0', width:'100%', height:'100%', backgroundColor:'#ffffff00', display: 'flex', alignItems: 'center', justifyContent: 'center', visibility: open === true ? 'visible' : 'hidden', zIndex: zIndex}}>
            <div style={{margin: '0px 10px'}}><center><h3>{status}</h3></center></div>
        </div>
    );
}

export default Progress;