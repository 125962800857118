import { useState } from 'react';

import { Link } from 'react-router-dom';

import { useQuery } from 'react-query';

import Menu from '../Menu';
import Footer from '../Controls/Footer';

import Pricing from '../Pricing';

import System from '../../system';

const Header = (props: any) => {    
    const { sunglass, image } = props;
    return (
        <table width='60%'>
            <thead></thead>
            <tbody>
                <tr>
                    <td width='100%' height='400' >
                    <div className='center' style={{width: '100%', height: '100%', padding: '0 5px', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                        <Link to='/details' state={{sunglass: sunglass}} style={{textDecoration: 'none'}}>
                            <img className='productsheaderimage' src={image} />
                        </Link>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>

    )
}

const Body = (props: any) => {
    const { image, onImage, sunglass } = props;
    return (
        <table width='40%'>
            <thead></thead>
            <tbody>
                <tr>
                    <td>
                    <div className='productsbody'>
                    <table width='100%'>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td width='100%' height='200' style={{backgroundColor: '#f0f0f0'}}>
                                <div className='center' style={{width: '100%', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                                    <img className='productsbodyimage' src={image} onClick={() => onImage(image)}/>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width='100%'>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td width='100%' height='180' valign='top'>
                                <span style={{display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap'}}>
                                    <span className='detailtitle'>{sunglass.name}</span><br/>
                                    {sunglass.bullets.map((bullet: any, index: any) => {
                                        return <span key={`bullet${index}`}>•&nbsp;{bullet}</span>
                                    })}
                                    {sunglass.bulletsExtra.map((bulletExtra: any, index: any) => {
                                        return <span key={`bulletExtra${index}`}>•&nbsp;{bulletExtra}</span>
                                    })}
                                </span>
                                </td>
                            </tr>
                            <tr>
                                <td width='100%' height='100%'>
                                <Pricing sunglass={sunglass} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )    
}

const Product = (props: any) => {
    const { sunglass } = props;
    const [ headerImage, setHeaderImage ] = useState(sunglass.images[1]);
    const [ bodyImage, setBodyImage ] = useState(sunglass.images[0]);
    function onImage(image: any) {
        setBodyImage(headerImage);
        setHeaderImage(image);
    }
    return (
        <>
        <Header sunglass={sunglass} image={headerImage} />
        <Body sunglass={sunglass} image={bodyImage} onImage={onImage} />
        </>
    );
}

const Products = () => {
    const { isLoading, data } = useQuery('Products', async () => {
        const system = new System();
        const result = await system.get({data: {messageId: 'default', messageTable: 'Products', message: {}}});
        return {sunglasses: result.message};
    });
    const sunglasses = isLoading === true ? [] : data?.sunglasses;
    return (
        <div>
            <Menu />
            <div>
                {sunglasses.map((sunglass: any, index: number) => {
                    return <div className='products' key={`product${index}`}>
                        <Product sunglass={sunglass} />
                </div>
                })}
            </div>
            <Footer position='relative' />
        </div>
    );
}

export default Products;