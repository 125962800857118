import { useState } from 'react';

import { useQuery } from 'react-query';

import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import Order from "../../order";

const PayPal = (props: any) => {
    const { order, onReviewOrder } = props;
    const [ shipping, setShipping ] = useState();
    const [ tax, setTax ] = useState();
    const { isLoading, isError, data, status, error } = useQuery('PayPal', async () => {
        const order = new Order();
        const result = await order.get({data: {messageId: 'paypal', message: {}}});
        const message = result.message;
        return message;
    });
    const intent = 'capture';
    const style: any = {shape: 'rect', layout: 'vertical'};

    async function getPayment(intent: string) {
        try {
            console.log('Creating PayPal order...');
            // Reduce item details.
            const items = order.map((item: any) => ({
                model: item.model,
                quantity: item.quantity
            }));
            const paypal = {
                brand_name: "HAVA SUNGLASSES",
                //return_url: "http://localhost:3000/checkout",
                //cancel_url: "http://localhost:3000/cart"
            };
            const response = await new Order().get({data: {messageId: intent, message: {items: items, paypal: paypal}}});
            if( response.result === false ) {
                console.error(`Unable to create PayPal order...${response.error}`);
                return { paymentId: '' }; // Empty payment Id.
            } else {
                console.log(`Created PayPal order ${response.message.paymentId}`);
                return response.message;
            }
        } catch (error: any) {
            console.error(error.message);
        }        
    }
    async function onApprove(paymentId: any) {
        console.log(`Approving PayPal order ${paymentId}`);
        try {
            const response = await new Order().get({data: {messageId: 'details', message: {payment: {id: paymentId}}}});
            console.log(`Approved PayPal order ${response.message.paymentId}`);
            return response.message;
        } catch (error) {
            console.error(error);
        }
    }
    async function onCreateOrder() {
        return getPayment(intent).then((order) => order.paymentId);
    }
    async function onApproveOrder(data: any) {
        const message = await onApprove(data.orderID);
        if( message.result === true ) {
            const missingDetails = tax === undefined || shipping === undefined;
            const details = missingDetails ? await onShippingOrder({
                // Make sure this is in PayPal format.
                orderID: message.paymentId,
                shippingAddress: {
                    city: message.shipping.address.city,
                    state: message.shipping.address.state,
                    countryCode: message.shipping.address.country,
                    postalCode: message.shipping.address.postal_code
                }
            }) : { tax: tax, shipment: shipping };
            onReviewOrder({
                id: message.paymentId,
                email: message.email,
                name: message.name,        
                shipping: {...message.shipping, ...details.shipment},
                tax: details.tax
            });
        }
    }
    async function onShippingOrder(data: any) {
        console.log(data.shippingAddress);
        const address = data === undefined ? undefined : data.shippingAddress;
        if( address !== undefined ) {
            console.log(`State is ${address.state}`);
            // Reduce item details.
            const items = order.map((item: any) => ({
                model: item.model,
                quantity: item.quantity
            }));
            // Update order details.
            const response = await new Order().set({data: {messageId: 'details', message: {
                payment: {id: data.orderID},
                items: items,
                address: {
                    city: address.city,
                    state: address.state,
                    country: address.countryCode,
                    postal_code: address.postalCode
                }}}});
            const message = response.message;
            console.log(message.result === true ? `Sales tax is $${message.tax.amount} at ${message.tax.rate}%` : 'Sales tax NOT updated!');
            console.log(message.result === true ? `Shipping is $${message.shipment.fee}` : `Shipping cost NOT updated!`);
            setShipping(message.shipment);
            setTax(message.tax);
            return message;
        }
    }
    async function onErrorOrder(error: any) {
        console.error(error);
    }
    return (
        <>
            {data !== undefined && data.paymentKey !== undefined &&
            <PayPalScriptProvider options={{
                'clientId': data.paymentKey,
                'intent': intent,
                'commit': false,
                'enable-funding': 'paylater',
                'disable-funding': 'card'
            }}>
                <PayPalButtons 
                    style={style}
                    createOrder={onCreateOrder}
                    forceReRender={[order]}
                    onApprove={onApproveOrder}
                    onShippingAddressChange={onShippingOrder}
                    onError={onErrorOrder}
                />
            </PayPalScriptProvider>}
        </>
    );
}

export default PayPal;