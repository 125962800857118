import { useState } from 'react';

import { AddressElement, PaymentElement,  } from '@stripe/react-stripe-js';

import Progress from '../Controls/Progress';

import Email, { EMAIL_REGEX } from '../Controls/Email';

import ReviewElement from './ReviewElement';

import Footer from '../Controls/Footer';

const CheckoutForm = (props: any) => {
    const { order, message, paypal, status, onReviewOrder, onPlaceOrder } = props;
    // Checkout information.
    const [ submit, setSubmit ] = useState(status);
    const [ reviewing, setReviewing ] = useState(paypal !== undefined);
    const [ checkingOut, setCheckingOut ] = useState(false);
    const [ email, setEmail ] = useState(paypal === undefined ? undefined : paypal.email);
    const [ emailError, setEmailError ] = useState(false);
    const [ shipping, setShipping ] = useState<any>(paypal === undefined ? undefined : {name: paypal.name, fee: paypal.shipping.fee, address: paypal.shipping.address});
    const [ billing, setBilling ] = useState<any>(paypal === undefined ? undefined : {vendor: 'PAYPAL'});
    const [ tax, setTax ] = useState<any>(paypal === undefined  ? undefined : paypal.tax);
    
    function onError(error: any) {
        console.error(error.message);
    }
    function onEmail(data: any) {
        setEmail(data);
        // Start dynamically checking for an email format if an error condition exists and '@' exists.
        if( emailError === true && String(data).includes('@') === true ) {
            setEmailError(EMAIL_REGEX.test(String(data)) === false);
        }
    }
    function onShipping(data: any) {
        setShipping(data.value);
    }
    async function onSubmit(e: any) {
        // Prevents page refresh and component states are lost.
        e.preventDefault();
        var formError = false;
        // Reviewing order?
        if( submit === 'REVIEW ORDER' && reviewing === false ) {
            // Manually handle email error.
            if( EMAIL_REGEX.test(String(email)) === false ) {
                setEmailError(true);
                formError = true;
            } else {
                setEmailError(false);
            }
            setSubmit('PLEASE WAIT')
            const { error, billing, shipment, tax } = await onReviewOrder({order: order, email: email, name: shipping.name, address: shipping.address});
            if( error ) {
                setReviewing(false);
                setSubmit('REVIEW ORDER')
                onError(error);
            } else {
                if( formError === true ) {
                    setReviewing(false);
                    setSubmit('REVIEW ORDER')
                } else {
                    if( billing ) {
                        billing.card = billing.card === 'AMERICAN_EXPRESS' ? 'AMEX' : billing.card;
                        setBilling(billing);
                    }
                    if( shipment ) {
                        setShipping({...shipping, ...shipment});
                    }
                    if( tax ) {
                        setTax(tax);
                    }
                    setReviewing(true);
                    setSubmit('PLACE ORDER');
                }
            }
        // Placing order?
        } else if( submit === 'PLACE ORDER' && reviewing === true ) {
            setCheckingOut(true);

            const { error } = await onPlaceOrder({
                email: email,
                name: shipping.name,
                billing: {
                    card: paypal === undefined ? billing.card : 'PAYPAL',
                    last4: paypal === undefined ? `. . .${billing.last4}` : '',
                    address: paypal === undefined ? billing.address : shipping.address
                },
                shipping: {
                    fee: shipping.fee,
                    address: shipping.address,
                },
                id: paypal === undefined ? billing.tokenId : paypal.id,
                tax: tax
            });
            if( error ) {
                setCheckingOut(false);
                setSubmit('REVIEW ORDER')
                onError(error);
            }
        }
    }
    return (
        <div>
            {reviewing === false &&
            <form onSubmit={onSubmit}>
                <div className='checkout' style={{padding: '0px 20px'}}>
                    <h3>CONTACT</h3>
                    <a>Email</a><br/>
                    <Email error={emailError} onChange={onEmail} />
                    <h3>SHIPPING INFORMATION</h3>
                    <AddressElement options={{mode: 'shipping'}} onChange={onShipping} />                        
                    <h3>BILLING INFORMATION</h3>
                    <PaymentElement />
                </div>
                <br/><br/><center><button className="rectangle" type='submit'><b>{submit}</b></button></center>
            </form>}
            {reviewing === true && checkingOut === false &&
            <div>
                <form onSubmit={onSubmit}>
                    {reviewing && <ReviewElement email={email} order={order} shipping={shipping} billing={billing} tax={tax} />}
                    <br/><br/><center><button className="rectangle" type='submit'><b>{submit}</b></button></center><br/>
                </form>
            </div>}
            <div className='center' style={{visibility: checkingOut === true ? 'visible' : 'hidden'}}>
                <Progress open={checkingOut} message={message} />
            </div>
            <Footer position={reviewing === false ? 'relative' : checkingOut === false ? undefined : 'absolute'} />
        </div>
    );
}

export default CheckoutForm;