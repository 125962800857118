import { useMemo } from "react";

// Cart version.
const CARTNAME = 'havacart.202404.03'

export const useCart = (name: string = '') : {
    cart: any;
    addToCart: (item: any) => any;
    removeFromCart: (item: any) => any;
    groupCart: (order: any, groupBy: string) => any;
    clearCart: () => void;
    addCartToOrder: (orderId: string) => any;
    getOrderId: () => string;
    } => {
    const cartName = String(name).length > 0 ? name : CARTNAME;
    // Cart expires after 30 days (in milliseconds).
    const exp = 24 * 60 * 60 * 1000; // Hours * Minutes * Seconds * Milliseconds.
    const item = localStorage.getItem(cartName);
    const cart = useMemo(() => {
        if( item === null ) {
            return [];
        } else {
            const storage = JSON.parse(String(item));
            const reserved = storage[0];

            // Cart expired (first index is always reserved, {activity: unixtimemilliseconds, orderId: xxxx})?
            if( Date.now()-reserved.activity > exp ) {
                // Clear cart.
                localStorage.removeItem(cartName);
                return [];
            } else {
                // Update cart activity, preserve orderId, if any.
                storage[0] = reserved.orderId === undefined ? {activity: Date.now()} : {activity: Date.now(), orderId: reserved.orderId};
                localStorage.setItem(cartName, JSON.stringify(storage));
            }
            return storage;
        }
    }, [item]);
    function groupCart(order: any, groupBy: string) {
        const group: any = [];
        /*const grouped =*/ order.reduce((items: any, item: any) => {
            const id = item[groupBy];
            if(!items[id]) {
                items[id] = [];
            }
            // Only push item with same Id once but always increment the quantity.
            if( items[id].length === 0 ) {
                item.quantity = 1;
                items[id].push(item);
                group.push(item);
            } else {
                // This changes the quantity of the item in group too.
                items[id][0].quantity++; 
            }
            return items;
        }, {});
        return group;
    }
    function getCart() {
        // Do not return first index (reserved), just the items.
        return cart.slice(1, cart.length);
    }
    function addToCart(item: any) {
        // First item?
        if( cart.length === 0 ) {
            // Date of cart activity.
            cart.push({activity: Date.now()});
        }
        // Add new item.
        cart.push(item);
        localStorage.setItem(cartName, JSON.stringify(cart));
        return getCart();
    }
    function removeFromCart(item: any) {
        // Any items?
        if( cart.length === 0 ) {
            return [];
        } else {
            const index = cart.findIndex((i: any) => i['id'] === item.id);
            if( index >= 0 ) {
                cart.splice(index, 1);
                // Any items left?
                if( cart.length === 1) {
                    // Only first index (reserved), clear cart.
                    clearCart();
                    return [];
                } else {
                    localStorage.setItem(cartName, JSON.stringify(cart));
                }
            }
        }
        return getCart();
    }
    function clearCart() {
        localStorage.removeItem(cartName);

        cart.splice(0, cart.length);
    }
    function addCartToOrder(orderId: string) {
        if( orderId !== undefined ) {
            const storage = JSON.parse(String(item));

            // Update cart order, if any, and activity.
            cart[0] = storage[0] = orderId === undefined ? {activity: Date.now()} : {activity: Date.now(), orderId: orderId};
            localStorage.setItem(cartName, JSON.stringify(storage));
        }
        return getCart();
    }
    function getOrderId() {
        if( cart.length === 0 ) {
            return undefined;
        } else {
            return cart[0].orderId;
        }
    }
    return {
        cart: getCart(),
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        groupCart: groupCart,
        clearCart: clearCart,
        addCartToOrder: addCartToOrder,
        getOrderId: getOrderId
    }
}
