export function getSale(sunglass: any, quantity: number) {
    const sale = sunglass.discount && Number(sunglass.discount) > 0 ? (sunglass.msrp-(sunglass.msrp*(sunglass.discount/100))) : sunglass.msrp;
    return quantity === undefined ? sale : quantity * sale;
}

const Pricing = (props: any) => {
    const { discountFirst, quantity, sunglass } = props;
    const sale = getSale(sunglass, quantity);
    const discount = sunglass.discount && Number(sunglass.discount) > 0 ? sunglass.discount : undefined;
    return (
        <span className='pricing'>
            {discount ? discountFirst ? quantity ?
            <><span className='status'>{`${discount}% OFF`}</span>&nbsp;<s>{`$${sunglass.msrp}`}</s>&nbsp;<b>{`$${(Math.round((sale/quantity)*100)/100).toFixed(2)} X ${quantity} = $${(Math.round(sale*100)/100).toFixed(2)}`}</b></> :
            <><span className='status'>{`${discount}% OFF`}</span>&nbsp;<s>{`$${sunglass.msrp}`}</s>&nbsp;<b>{`$${(Math.round(sale*100)/100).toFixed(2)}}`}</b></> :
            <><s>{`$${sunglass.msrp}`}</s>&nbsp;<b>{`$${(Math.round(sale*100)/100).toFixed(2)}`}</b><span className='status'>&nbsp;{`• ${sunglass.discount}% OFF`}</span></> :
            quantity ? <><b>{`$${sunglass.msrp} X ${quantity} = $${sale}`}</b></> : <><b>{`$${sunglass.msrp}`}</b></>}
        </span>
    )
}

export default Pricing;