import { useEffect, useState } from "react";

import { useMutation, useQuery } from "react-query";

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Menu from "../Menu";

import Pricing from "../Pricing";

import Email, { EMAIL_REGEX } from "../Controls/Email";

import Footer from "../Controls/Footer";

import { useCart } from "../../hooks";

import System from "../../system";
import Order from "../../order";

const Details = () => {
    const { cart, addToCart, groupCart } = useCart();
    const [ sale, setSale ] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [ email, setEmail ] = useState();
    const [ emailError, setEmailError ] = useState(false);
    const amazon = { clicked: false };
    const quantity = location !== undefined && location.state !== undefined && location.state !== null ? location.state.quantity : undefined;
    const { isLoading, data } = useQuery('Product', async () => {
        const model = location !== undefined && location.state !== undefined && location.state.sunglass !== undefined ? location.state.sunglass.model : searchParams.get('model');
        if( model === undefined || model === null ) {
            return {sunglass: undefined};
        } else {
            const result = await new System().get({data: {messageId: 'default', messageTable: 'Products', message: {model: model}}});
            const sunglasses = result.message;
            setSunglass(sunglasses);
            return {sunglass: sunglasses};
        }
        // Use the quantity if provided, otherwise query to get the latest.
    }, {enabled: searchParams.size > 0 || quantity === undefined});
    interface ILog {params: any};
    const { mutateAsync: createLog, /*isLoading: isLogging*/ } = useMutation(async ({params}: ILog) => {
        if( params !== undefined && params.sunglasses !== undefined ) {
            const result = await new Order().create({data: {messageId: 'default', messageTable: 'Logs', message: 
                {
                    description: `${params.cart === undefined ? params.amazon === undefined ? 'Details' : 'Amazon' : 'Cart'}: name: ${params.sunglasses.name}, model: ${params.sunglasses.model}.`,
                    level: 'Info'
                }
            }});
        }
    });
    const [ sunglass, setSunglass ] = useState(location.state === undefined || location.state === null ? getSunglasses(searchParams) : getSunglasses(location.state));
    const [ image, setImage ] = useState(sunglass === undefined ? undefined : sunglass.images[0]);
    useEffect(() => {
        if( sunglass === undefined ) {
            navigate('/');
        } else {
            createLog({params: {sunglasses: sunglass}});
        }
    }, [location, sunglass]);
    function getSunglasses(params : any) {
        const sunglasses = (() => {
            // Any sunglasses already defined?
            if( params.sunglass === undefined ) {
                // Any model defined?
                if( isLoading === true || data === undefined || data.sunglass === undefined ) {
                    return undefined;
                } else {
                    return data.sunglass;
                }                
            } else {
                // Sunglasses already defined.

                // Update quantity.  This is not provided coming from a cart but will be provided with a query.
                params.sunglass.quantity = params.quantity;

                return params.sunglass;
            }
        })();
        return sunglasses;
    }
    function onEmail(data: any) {
        setEmail(data);
        // Start dynamically checking for an email format if an error condition exists and '@' exists.
        if( emailError === true && String(data).includes('@') === true ) {
            setEmailError(EMAIL_REGEX.test(String(data)) === false);
        }
    }
    function onAddToCart(sunglass: any) {
        // Only add to cart if quantity is not exceeded.
        const order = groupCart(cart, 'id');
        const ordering = order.find((item: any) => item.model === sunglass.model);
        if( ordering === undefined || ordering.quantity < sunglass.quantity ) {
            // Log the cart.
            createLog({params: {sunglasses: sunglass, cart: addToCart(sunglass)}});
            // Local sales count helps update cart on the menu.
            setSale(sale+1);
        }
    }
    function onAddToWaitlist(sunglass: any) {

    }
    function onAmazon(sunglass: any) {
        if( sunglass && sunglass.urlAmazon && amazon.clicked === false ) {
            amazon.clicked = true;
            createLog({params: {sunglasses: sunglass, amazon: sunglass.urlAmazon}});
            // Opens up the Amazon product listing in a new tab.
            window.open(sunglass.urlAmazon, '_blank');
        }
    }
    const limited = 10;
    const minimum = 5;
    return (
        <div>
            <Menu />
            {sunglass && <>
            <div className='detail'>
                <table width='60%'>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td height='400'>
                            <div className='center' onClick={() => sunglass.urlAmazon !== undefined && onAmazon(sunglass)} style={{cursor: sunglass.urlAmazon && 'pointer', padding: '0 5px', width: '100%', height: '100%', backgroundColor: '#f0f0f0', backgroundImage: `${sunglass.urlAmazon !== undefined && "url('/images/amazon.png')"}`, backgroundPosition: 'right 25px bottom 25px', backgroundSize: '100px', backgroundRepeat: 'no-repeat'}}>
                                <img className='detailimage' src={image === undefined ? sunglass === undefined ? undefined : sunglass.images[0] : image} />
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <table width='100%'>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td width='25%' height='100' style={{height: '100%', backgroundColor: '#f0f0f0'}}>
                                        <div className='center' style={{width: '100%', height: '100%', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                                            <img src={sunglass.images[0]} width='75' onClick={() => setImage(sunglass.images[0])}/>
                                        </div>
                                        </td>
                                        <td width='25%' height='100'>
                                        <div className='center' style={{width: '100%', height: '100%', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                                            <img src={sunglass.images[1]} width='75' onClick={() => setImage(sunglass.images[1])} />
                                        </div>
                                        </td>
                                        <td width='25%' height='100'>
                                        <div className='center' style={{width: '100%', height: '100%', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                                            <img src={sunglass.images[2]} width='75' onClick={() => setImage(sunglass.images[2])} />
                                        </div>
                                        </td>
                                        <td width='25%' height='100'>
                                        <div className='center' style={{width: '100%', height: '100%', backgroundColor: '#f0f0f0', cursor: 'pointer'}}>
                                            <img src={sunglass.images[3]} width='75' onClick={() => setImage(sunglass.images[3])} />
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width='40%' style={{margin: '10px'}}>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td align='left' valign='top'>
                            &nbsp;<span className='detailtitle'>{sunglass.name}</span>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={2} align='left' valign='top'>
                            <span style={{display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap'}}>
                                {sunglass.bullets.map((bullet: any, index: any) => {
                                    return <span key={`bullet${index}`}>•&nbsp;{bullet}</span>
                                })}
                                {sunglass.bulletsExtra.map((bulletExtra: any, index: any) => {
                                    return <span key={`bulletExtra${index}`}>•&nbsp;{bulletExtra}</span>
                                })}
                                <br/>
                                {sunglass.frame.map((frame: any, index: any) => {
                                    return <span key={`frame${index}`}>•&nbsp;{frame}</span>
                                })}
                            </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table width='100%'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td>
                        <div style={{margin: '10px 10px'}}>
                            Availability:&nbsp;{sunglass.quantity === undefined ? <></> : <span className='status'>{sunglass.quantity === 0 ? 'Out of Stock' : sunglass.quantity < minimum ? `Only ${sunglass.quantity} Left` : sunglass.quantity < limited ? 'Limited' : <span style={{color: '#000000'}}>In Stock</span>}</span>}<br/>
                            <span className='pricing'>
                                <br/><Pricing sunglass={sunglass} />&nbsp;&nbsp;                                
                                { // Email to add to waitlist for out of stock item.
                                sunglass.quantity === 0 && 
                                <div style={{width: '50%', paddingTop: '10px'}}>
                                    <Email error={emailError} onChange={onEmail} />
                                </div>}
                                <Link to='/cart'>
                                    <button className="rectangle" onClick={() => sunglass.quantity === 0 ? onAddToWaitlist(sunglass) : onAddToCart(sunglass)}><b>{sunglass.quantity === 0 ? 'ADD TO WAITLIST' : 'ADD TO CART'}</b></button>
                                </Link><br/><br/>
                                <b>FREE SHIPPING in the Continental US</b><br/><br/>
                            </span>
                        </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </>}
            {sunglass === undefined ? <Footer position='absolute' /> : <Footer />}
        </div>
    );
}

export default Details;