import { Link } from "react-router-dom";

import Pricing from "../Pricing";

const CartItem = (props: any) => {
    const { sunglass, onDelete } = props;
    return (
        <div>
            <table width='100%' style={{margin: '10px 0px'}}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td rowSpan={3} height='100px' style={{backgroundColor: '#f0f0f0'}}>
                        <div className='center' style={{width: '100px', padding: '10px 10px', backgroundColor: '#f0f0f0'}}>
                            <Link to='/details' state={{sunglass: sunglass}} style={{textDecoration: 'none'}}>
                                <img src={sunglass.images[0]} width='100' />
                            </Link>
                        </div>
                        </td>
                        <td>
                        <span>&nbsp;<b>{sunglass.name}&nbsp;({sunglass.quantity})</b></span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{display:'flex', flexDirection:'column'}}>
                        {sunglass.bullets.map((bullet: any, index: any) => {
                            return <span key={`bullet${index}`}>&nbsp;•&nbsp;{bullet}</span>
                        })}
                        </td>
                    </tr>
                    <tr>
                        <td width='100%' align='right' valign='bottom'>
                        <table width='100%'>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td align='left'>
                                    {onDelete && <img src='/images/trash.png' width='25' style={{cursor: 'pointer'}} onClick={() => onDelete(sunglass)} />}
                                    </td>
                                    <td width='100%' align='right'>
                                    <Pricing sunglass={sunglass} quantity={sunglass === undefined ? undefined : sunglass.quantity} discountFirst={true} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CartItem;