import { Network } from '@client/network';

export const apiDataURL = 'https://api.havasunglasses.com';

export default class System {
    protected client: any;

    protected clientId: string = '8DDAB2A7660E40C48C5ADB7FBA197E99';

    constructor(url: string = apiDataURL) {
        this.client = new Network(url, this.clientId);
    }

    async create(payload : any) {

    }

    async delete(payload : any) {

    }

    async get(payload : any) {
        return await this.getSystem(payload);
    }

    async set(payload : any) {

    }

    protected async post(path: string, payload: any) {
        return await this.client.post(path, payload);
    }

    private async getSystem(payload : any) {
        return await this.post('/system/getsystem', payload);
    }
}
