import { Link } from "react-router-dom";

import Pricing from "../../Pricing";

const Catalog = (props: any) => {
    const { items, columns } = props;
    const limited = 10;
    const minimum = 5;
    function EmptyItems() {
        const emptyItems = [];
        for( let x = items.length; x < columns; x++ ) {
            emptyItems.push(<div className='center' style={{width: '100%', display:'flex', flexDirection:'column'}} key={`sunglass${x}`} />);
        }
        return emptyItems;
    }
    return (
        <div className='center' style={{width: '100%'}}>
        {items && items.map((item: any, index: number) => {
            return <div className='center' style={{width: '100%', display:'flex', flexDirection:'column'}} key={`sunglass${index}`}>
                <div className='product' style={{padding: '10px 20px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <span className='status'>{item.quantity === 0 ? 'Out of Stock' : item.quantity < minimum ? `Only ${item.quantity} Left` : item.quantity < limited ? 'Limited' : <span style={{color: '#000000'}}>In Stock</span>}</span><br/>
                    <Link to='/details' state={{sunglass: item, quantity: item.quantity}} style={{textDecoration: 'none'}}>
                        <img src={item.images[0]} height='50px' />
                    </Link>
                    <span style={{whiteSpace: 'nowrap'}}><b>{item.name}</b></span><br/>
                    <span style={{display: 'flex', alignItems: 'left', flexDirection: 'column', whiteSpace: 'nowrap'}}>
                        {item.bullets.map((bullet: any, index: number) => {
                            return <span key={`bullet${index}`}>•&nbsp;{bullet}</span>
                        })}
                    </span>
                </div>
                <br/><Pricing sunglass={item} /><br/>
            </div>
        })}
        {items && items.length < columns && <>{EmptyItems()}</>}
        </div>
    );
}

export default Catalog;