export class Network {

    protected url: string;
    protected id: string;

    constructor(url: string, id: string) {
        this.url = url;
        this.id = id;
    }

    async post(path: string, payload: any) {
        try {
            const headers: any = {
                'content-type': 'application/json',
                'client-id': this.id
            };
            if( payload.token !== undefined ) {
                headers['authorization'] = `Bearer ${payload.token}`;
            }
            const response = await fetch(this.url + path, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(payload.data)
            });
            const data = await response.json();
            if(Object.keys(data).length === 0) {
                return {data: {body: {message: {result: false, logout: false, error: response.statusText }}}};
            }
            return data.body === undefined ? data : data.body;
        } catch (error: any) {
            console.error(error.message);
            return undefined;
        }
    }
}
