import System, { apiDataURL } from "../system";

export default class Order extends System {
    constructor(url: string = apiDataURL) {
        super(url);
    }
    async create(payload : any) {
        return await this.createOrder(payload);
    }

    async delete(payload : any) {
        return await this.deleteOrder(payload);
    }

    async get(payload : any) {
        return await this.getOrder(payload);
    }

    async set(payload : any) {
        return await this.setOrder(payload);
    }
    private async createOrder(payload : any) {
        return await super.post('/orders/createorder', payload);
    }

    private async deleteOrder(payload : any) {
        return await super.post('/orders/deleteorder', payload);
    }

    private async getOrder(payload : any) {
        return await super.post('/orders/getorder', payload);
    }

    private async setOrder(payload : any) {
        return await super.post('/orders/setorder', payload);
    }
}
