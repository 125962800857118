import { getSale } from "../Pricing";

export function getSubtotal(order: any) {
    var subtotal = 0;
    for(const sunglass of order) {
        subtotal += getSale(sunglass, sunglass === undefined ? undefined : sunglass.quantity);
    }
    return subtotal;
}

const CartSummary = (props: any) => {
    const { order, shipping, tax } = props;
    const freeShipping = shipping === undefined || shipping.fee === undefined || shipping.fee === 0 || shipping.fee === '0.00';
    function getTotal(order: any) {
        const subtotal: any = getSubtotal(order);
        const total = tax === undefined ? subtotal : parseFloat(subtotal)+parseFloat(freeShipping ? 0 : shipping.fee)+parseFloat(tax.amount);
        return total;
    }
    return (
        <table width='100%'>
            <thead></thead>
            <tbody>
                <tr>
                    <td width='100%' colSpan={2}>
                    <hr/>
                    </td>
                </tr>
                <tr>
                    <td align='right'>
                    <b>Subtotal</b>&nbsp;
                    </td>
                    <td width='30%' align='right'>
                    <b>{`${(Math.round(getSubtotal(order) * 100) / 100).toFixed(2)}`}</b>&nbsp;
                    </td>
                </tr>
                <tr>
                    <td align='right'>
                    <b>Tax</b>&nbsp;
                    </td>
                    <td width='30%' align='right'>
                    {tax === undefined ? <span style={{fontSize: 'small'}}>TO BE CALCULATED</span> : <b>{`${(Math.round(tax.amount * 100) / 100).toFixed(2)}`}</b>}&nbsp;
                    </td>
                </tr>
                <tr>
                    <td align='right'>
                    <b>Shipping</b>&nbsp;
                    </td>
                    <td width='30%' align='right'>
                    <b>{freeShipping ? 'FREE' : `${shipping.fee}`}</b>&nbsp;
                    </td>
                </tr>
                <tr>
                    <td width='100%' colSpan={2}>
                    <hr/>
                    </td>
                </tr>
                <tr>
                    <td align='right'>
                    <b>Total</b>&nbsp;
                    </td>
                    <td width='30%' align='right'>
                    <b>{`$${(Math.round((getTotal(order)) * 100) / 100).toFixed(2)}`}</b>&nbsp;
                    </td>
                </tr>
            </tbody>
        </table>

    );
}

export default CartSummary;