import { useQuery } from "react-query";

import Menu from "../Menu";

import Footer from "../Controls/Footer";

import System from "../../system";

const About = () => {
    const { isLoading, isError, data, status, error } = useQuery('About', async () => {
        const system = new System();
        const result = await system.get({data: {messageId: 'default', message: {}}});
        const message = result.message;
        return message;
    });
    return (
        <div>
            <Menu /><br/><br/>
            <div style={{marginTop: 40, position: 'absolute', left: '0px', top: '40px', backgroundColor:'#ffffff00', display: 'flex', alignItems: 'center', justifyContent: 'left', visibility: 'visible', zIndex: -1}}>
                {data !== undefined && data.about !== undefined && data.about.file !== undefined && <img src={data.about.file} height='600' />}
            </div>
            <div className='about'>
                {data !== undefined && data.about !== undefined && data.about.message !== undefined && <span>{data.about.message}</span>}
            </div>
            <br/><br/><br/><br/><br/>
            <Footer image='/images/cases.png' />
        </div>
    );
}

export default About;