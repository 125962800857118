import { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useCart } from '../../hooks';

const CartMenuItem = (props: any) => {
    const { onClick } = props;
    const { cart } = useCart();
    return (
        <div>
            <Link className='cartmenu' to='/cart'>
                <img src='/images/cart.png' height='30px' onClick={onClick} />
                {cart.length > 0 && <span className='circle' onClick={onClick}>{cart.length}</span>}
            </Link>
        </div>
    )
}

export default CartMenuItem;