import { useState } from 'react';

// Found in ra-core (React-Admin).
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

const Email = (props: any) => {
    const { error, onChange } = props;

    function onEmail(data: any) {
        onChange(data.target.value);
    }
    return (
        <>
            <input className='email' name='email' type='text' placeholder='Email' onChange={onEmail} style={{border: error ? '2px solid #df1b41' : ''}} />
            <label style={{color: '#df1b41', visibility: error ? 'visible' : 'hidden'}}>Please provide a valid email address</label>
        </>
    );
}

export default Email;